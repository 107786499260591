import React from 'react';
import './App.scss';

export default ({loaded}) => {
    return (
        <div id={"about"} className={`showcase ${loaded ? 'loaded' : ''}`}>
            <div className="container">
                <h1>WFG California Commercial Services</h1>
                At WFG National Commercial Services, we dedicate our time and resources to efficiently handling your
                complex multi-state transactions.
                <hr/>
                <div className={"section"}>
                    <p className={"description"}>
                        Our commercial division team can assist with the complexities of closing all commercial
                        transactions. With a client-focused approach, including a single point of contact for clients
                        and rapid turnaround time on orders, WFG National Title’s flat administrative organization
                        allows for quick response to client needs. The Williston Financial Group family of title
                        insurers is currently licensed in 49 States. The company is a full service provider of title
                        insurance and real estate settlement services for lender, brokers and principals on transactions
                        nationwide.
                    </p>
                </div>
            </div>
        </div>
    )
}