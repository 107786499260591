import React from 'react';
import './App.scss';
import Logo from './img/NTIC-Logo-Med.png'

export default ({config}) => {
    const {home} = config;
    return (
        <header className="App-header">
            <div className={"container"}>
                <a href={home} title={"California Commercial Services"}><img alt={"WFG Logo"} src={Logo} /></a>
            </div>
        </header>
    )
}