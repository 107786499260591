import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './App.scss';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.primaryInput = null;
    }

    componentDidUpdate() {
        if(this.props.focusPrimary) {
            this.primaryInput.focus();
        }
    }

    render() {
        const {config, loaded} = this.props;
        const {login_url, forgot_password, need_account} = config;
        return (
            <div className={`login ${loaded ? 'loaded' : ''}`}>
                <div className="container">
                    <form action={login_url} method={"post"}>
                        <div className={"auth"}>
                            <div className={"themed-input"}>
                                <i className={"fa fa-user"}/>
                                <input ref={(input => {
                                    this.primaryInput = input;
                                })} spellCheck={false} required={true}
                                       type={"text"} name={"username"}
                                       placeholder={"Username"}/>
                                <div className={"line"}/>
                            </div>
                            <div className={"themed-input"}>
                                <i className={"fa fa-lock"}/><input spellCheck={false} required={true} type={"password"}
                                                                    name={"password"} placeholder={"Password"}/>
                                <div className={"line"}/>
                            </div>
                            <button type={"submit"}>Login<i className={"fa fa-arrow-right"}/></button>
                            <br/>
                            <span>
                            <a href={forgot_password}>Forgot Password?</a> | <a href={need_account}>Need Account?</a>
                        </span>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Login;