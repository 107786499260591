import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Login from './Login';
import Showcase from './Showcase';
import ReactHead from './Head';
import './App.scss';

import config from './config';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            loaded: false,
            focusPrimary: false
        }
    }

    componentDidMount() {
        const setDelay = (key, length) => {
            setTimeout(function() {
                this.setState({[key]: true})
            }.bind(this), length);
        };
        this.loadingId = setDelay('loaded', 400);
        this.focusPrimaryId = setDelay('focusPrimary', 1200);
    }

    componentWillUnmount() {
        ['loadingId', 'focusPrimaryId'].forEach(id => {
            if(this[id]) clearTimeout(this[id]);
        });
    }

    render() {
        const {loaded, focusPrimary} = this.state;
        return (
            <div className="App">
                <ReactHead />
                {/* Google Tag Manager (noscript) */}
                <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=${config.GTM_ID}"
                                  height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
                {/* End Google Tag Manager (noscript) */}
                <Header config={config}/>
                <div className={"App-body"}>
                    <Login config={config} loaded={loaded} focusPrimary={focusPrimary}/>
                    <Showcase loaded={loaded}/>
                </div>
                <Footer config={config}/>
            </div>
        );
    }
}

export default App;
