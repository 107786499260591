import React from 'react';
import Helmet from 'react-helmet';
import Config from './config';
import './App.scss';

const {CCPA_DATA_DOMAIN, GA_UAID, GTM_ID} = Config;

export default () => {
    return (
        <Helmet>
            {/* OneTrust Cookies Consent Notice start */}
            <script type="text/javascript" src={`https://cdn.cookielaw.org/consent/${CCPA_DATA_DOMAIN}/OtAutoBlock.js`}></script>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={CCPA_DATA_DOMAIN}></script>
            <script type="text/javascript">{`
                        function OptanonWrapper() { }
                    `}</script>
            {/* OneTrust Cookies Consent Notice end */}
            {/* Global site tag (gtag.js) - Google Analytics */}
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_UAID}`}></script>
            <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${GA_UAID}');
            `}</script>
            {/* Google Tag Manager */}
            <script>{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
            `}</script>
            {/* End Google Tag Manager */}
        </Helmet>
    )
}