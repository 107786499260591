const is_production_hostname = process.env.NODE_ENV === 'production'
    && !RegExp('^(localhost|dev-|qa-|uat-)|.*(.dev.*)$').test(document.location.hostname);

export default {
    CCPA_DATA_DOMAIN: '8ad9e369-612d-4ca8-83b5-2b9ebd40850d' + (is_production_hostname ? '' : '-test'),
    GA_UAID: is_production_hostname ? 'UA-155605499-1' : 'UA-156684254-1',
    GTM_ID: is_production_hostname ? 'GTM-KT7PVL8' : 'GTM-W5KBJF2',
    login_url: 'https://titlepro247.com/logon.aspx',
    forgot_password: 'https://www.titlepro247.com/ForgotPassword',
    need_account: 'https://titlepro247.com/Register/Book',
    hours: "M-F 8am-5pm PST",
    phone: "555-555-5555",
    email: "cs@wfgprocommercial.com",
    home: "https://california.wfgnationaltitle.com/locations/commercial/",
    privacy_policy: "https://national.wfgnationaltitle.com/privacy-policy",
    contact_us: "https://california.wfgnationaltitle.com/contact",
    customer_service: "https://california.wfgnationaltitle.com/customer-service/"
}