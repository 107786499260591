import React from 'react';
import './App.scss';

export default ({config}) => {
    const {privacy_policy, contact_us, customer_service} = config;
    const date = new Date();
    return (
        <footer className={"App-footer"}>
            <div className={"links"}>
                <a href={privacy_policy}>Privacy Policy</a><span> | </span>
                <a href={contact_us}>Contact Us</a><span> | </span>
                <a href={customer_service}>California Customer Service</a>
            </div>
            <span className={"copyright"}>
                <p>All Data Deemed Reliable, But Not Guaranteed</p>
                WFG © {date.getFullYear()}
            </span>
        </footer>
    )
}